<template>
  <div
    @click.prevent="!headerItem && $emit('select-user', user)"
    :class="{ 'list-item--header': headerItem }"
    class="h6 list-item list-item--zoom-user"
  >
    <!-- Name -->
    <div class="fullname inline grow ellipsis">
      <template v-if="!headerItem && user">
        <user-profile-image
          v-if="user && user.userImage"
          class="inline"
          :image-alt="`${fullName} image`"
          :image-src="user.userImage"
          :selectable="false"
          :subscriber="Boolean((user || {}).stripeSubActive)"
        />

        <i v-else class="fas fa-user-circle grey-light--text" />
        <hr class="divider divider--vertical" />
      </template>

      <strong>{{ fullName }}</strong>
    </div>

    <!-- User verified -->
    <span class="is-verified">
      <i :class="verifiedClass"></i>
      <hr class="divider divider--vertical" />
      <template v-if="headerItem">Email verified </template>
      <span class="email ellipsis" v-else>
        {{ user.email }}
      </span>
      <hr class="divider divider--vertical" />
    </span>

    <!-- Meeting ID -->
    <span class="pmi link">
      {{ user ? user.id : "Zoom ID" }}
    </span>
  </div>
</template>

<script>
import UserProfileImage from "./UserProfileImage.vue";

export default {
  name: "ZoomUserListItem",

  components: { UserProfileImage },

  props: { headerItem: Boolean, user: Object },

  computed: {
    fullName() {
      return this.user ? this.user.fullName : "Full Name";
    },

    role() {
      return this.user ? this.user.role : "Role";
    },

    verifiedClass() {
      const user = this.user || {};
      if (!user.pmi) return null;
      return {
        "fas fa-check-circle grey-light--text": !user.id,
        "fas fa-check-circle success--text": user.verified === 1,
        "fas fa-times-circle error--text": user.id && user.verified !== 1
      };
    },

    username() {
      return this.user ? this.user.username : "Username";
    }
  }
};
</script>

<style lang="scss">
.list-item--zoom-user {
  &.h6 {
    color: #000;
    font-weight: normal;
  }

  .email {
    font-weight: bolder;
    text-transform: initial;
  }

  .fa-user-circle {
    font-size: 2.1rem;
  }

  .fullname {
    max-width: $half;
  }

  .image-loader--loading {
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
  }

  .is-verified {
    align-items: center;
    display: inline-flex;
    flex-grow: 1;
    text-transform: uppercase;
    width: 25%;
  }

  .is-verified,
  .pmi {
    flex-shrink: 0;
    letter-spacing: $xtiny;
  }
}

@media screen and (max-width: 600px) {
  .list-item--zoom-user {
    height: auto;
    padding-bottom: $xxs;
    padding-top: $xxs;
    flex-wrap: wrap;

    .fullname {
      width: $parent;
      order: 1;
    }

    .is-verified,
    .pmi {
      @include ellipsis;
      order: 10;
      text-align: right;
      max-width: $half;
      width: $half;
    }

    .pmi {
      flex-shrink: 0;
      width: auto;
    }
  }
}
</style>
